<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-row
      v-if="purchaseData"
      class="print-container"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card>
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-dark ml-0"
          >
            <b-tab active>
              <template #title>
                <span>{{ $t('Purchase') }}</span>
              </template>

              <section class="invoice-preview-wrapper">

                <b-row
                  class="invoice-preview"
                >
                  <b-col
                    cols="12"
                  >
                    <div
                      class="invoice-preview-card"
                    >
                      <div class="mt-1">
                        <div class="d-flex justify-content-center bd-highlight">
                          <div class="bd-highlight text-center">
                            <b-img
                              :src="configs.business_logo"
                              width="128"
                            />
                            <h3 class="mb-0">
                              {{ configs.business_name }}
                            </h3>
                            <p class="mb-1">
                              {{ configs.business_address }}. {{ configs.business_phone }}, {{ configs.business_email }}, {{ configs.business_website }}
                            </p>
                          </div>
                        </div>

                        <!-- Spacer -->
                        <hr class="invoice-spacing">

                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Invoice') }}: </span>#{{ purchaseData.id }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="float-right"><span class="text-dark font-weight-bold">{{ $t('Date') }}: </span>{{ purchaseData.purchase_date }}</span>
                          </div>
                        </div>
                        <div class="d-flex bd-highlight">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark mb-2 font-weight-bold">{{ $t('Branch') }}: </span>{{ purchaseData.branch_name }}
                          </div>
                          <div class="pb-1 bd-highlight">
                            <span class="text-dark mb-2 font-weight-bold">{{ $t('Ref') }}: </span>{{ purchaseData.reference }}
                          </div>
                        </div>

                        <div class="d-flex bd-highlight border-bottom">
                          <div class="mr-auto pb-1 bd-highlight">
                            <span class="text-dark font-weight-bold">{{ $t('Supplier') }}: </span>{{ purchaseData.supplier.name }}&nbsp;
                            [{{ purchaseData.supplier.phone }} - {{ purchaseData.supplier.address }}]
                          </div>
                          <div class="pb-1 bd-highlight">
                            <b-badge
                              pill
                              :variant="`light-${resolveStatusVariant(purchaseData.status)}`"
                              class="text-capitalize"
                            >
                              {{ resolveStatus(purchaseData.status) }}
                            </b-badge>
                          </div>
                        </div>
                        <!-- Invoice Description: Table -->
                        <b-table-lite
                          class="mt-1"
                          hover
                          bordered
                          responsive
                          :items="purchaseData.purchase_items"
                          :fields="[{ label: '', key: 'sr'}, { label: $t('Item'), key: 'item'}, { label: $t('Price'), key: 'price', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Qty'), key: 'quantity', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Unit'), key: 'unit', tdClass: 'text-right', thClass: 'text-right'}, { label: $t('Amt'), key: 'amount', tdClass: 'text-right', thClass: 'text-right'}]"
                          :small="isSmallScreen"
                        >
                          <!-- Column: Price -->
                          <template #cell(price)="data">
                            {{ data.item.price.toLocaleString() }}
                          </template>
                          <!-- Column: Amount -->
                          <template #cell(amount)="data">
                            {{ data.item.amount.toLocaleString() }}
                          </template>

                        </b-table-lite>
                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ purchaseData.total_amount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight pl-1 w-180px ">
                            {{ $t('Total Amount') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ purchaseData.discount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1">
                            {{ $t('Discount') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-0-75">
                          <div class="bd-highlight w-120px text-right pr-2rem font-weight-bold pt-0-75 border-top">
                            {{ purchaseData.net_amount.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1 pt-0-75 border-top">
                            {{ $t('Net') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-1">
                          <div class="bd-highlight w-120px text-right pr-2rem">
                            {{ purchaseData.total_payment.toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1">
                            {{ $t('Cash Receipt') }}:
                          </div>
                        </div>

                        <div class="d-flex flex-row-reverse bd-highlight mb-1">
                          <div class="bd-highlight w-120px text-right pr-2rem font-weight-bold  pt-0-75 border-top">
                            {{ (purchaseData.net_amount - purchaseData.total_payment).toLocaleString() }}
                          </div>
                          <div class="bd-highlight w-180px pl-1  pt-0-75 border-top">
                            {{ $t('Remaining Amount') }}:
                          </div>
                        </div>

                        <b-row>
                          <b-col
                            cols="12 text-center"
                          >
                            <small>
                              {{ purchaseData.remark }}
                            </small>
                          </b-col>
                        </b-row>

                      </div>

                    </div>
                  </b-col>
                </b-row>

              </section>
            </b-tab>
          </b-tabs>

        </b-card>

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        xl="3"
        md="4"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-1"
            />
            {{ $t('Print') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-if="$can('update', 'purchase')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            :to="{ name: 'purchase-edit', params: { id: purchaseData.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-1"
            />
            {{ $t('Edit') }}
          </b-button>

          <!-- Button: Close -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="close"
          >
            <feather-icon
              icon="PowerIcon"
              class="mr-1"
            />
            {{ $t('Close') }}
          </b-button>

        </b-card>
      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BButton, BTabs, BTab, BTableLite, BImg, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router/index'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import purchaseStoreModule from '../purchaseStoreModule'
import configData from '../../../common/configModule'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableLite,
    BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  created() {

  },
  methods: {
    // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
    resolveStatus(status) {
      if (status === 1) return this.$t('Unpaid')
      if (status === 2) return this.$t('Partially Paid')
      return this.$t('Paid')
    },
    close() {
      this.$router.push({ name: 'purchase-list' })
    },
  },
  setup() {
    const currentBreakpoint = computed(() => store.getters['app/currentBreakPoint'])
    const isSmallScreen = (currentBreakpoint.value === 'sm' || currentBreakpoint.value === 'xs')
    const STORE_MODULE_NAME = 'purchase'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const purchaseData = ref({
      purchase_date: null,
      supplier: [],
      supplier_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_payment: 0,
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      purchase_items: [],
      purchase_payments: [],
    })

    const purchaseId = router.currentRoute.params.id

    store.dispatch('purchase/fetchOne', { id: purchaseId })
      .then(response => {
        const dataItems = []
        const dataPayments = []
        const responseItems = response.data.data.purchase_items
        const responsePayments = response.data.data.purchase_payments
        for (let i = 0; i < responseItems.length; i += 1) {
          dataItems.push({
            sr: (i + 1),
            item_id: responseItems[i].item_id,
            item: `${responseItems[i].item.sku} ${responseItems[i].item.name}`,
            price: responseItems[i].price,
            quantity: responseItems[i].quantity,
            unit: responseItems[i].item.unit,
            amount: responseItems[i].amount,
          })
        }
        for (let i = 0; i < responsePayments.length; i += 1) {
          dataPayments.push({
            sr: (i + 1),
            payment_date: responseItems[i].payment_date,
            amount: responseItems[i].amount,
            reference: responseItems[i].reference,
          })
        }

        const responseData = {
          id: response.data.data.id,
          purchase_date: response.data.data.purchase_date,
          supplier: response.data.data.supplier,
          supplier_id: response.data.data.supplier_id,
          total_amount: response.data.data.total_amount,
          discount: response.data.data.discount,
          net_amount: response.data.data.net_amount,
          total_payment: response.data.data.total_payment,
          reference: response.data.data.reference,
          remark: response.data.data.remark,
          branch_name: response.data.data.branch_name,
          status: response.data.data.status,
          purchase_items: dataItems,
          purchase_payments: dataPayments,
        }
        purchaseData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          purchaseData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    const resolveStatusVariant = status => {
      if (status === 1) return 'danger'
      if (status === 2) return 'warning'
      if (status === 3) return 'success'
      if (status === 4) return 'secondary'
      return 'primary'
    }
    const { configs } = configData()

    return {
      isSmallScreen,
      purchaseData,
      printInvoice,
      configs,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-dark {
  color: #000000;
}
.w-120px {
  width: 120px;
}
.w-180px {
  width: 180px;
}
.pr-2rem {
  padding-right: 2rem !important;
}
.tabStyle {
  color : black !important;
}
[dir] .nav-tabs .nav-link {
  padding-left: 0;
}
.mb-0-75 {
  margin-bottom: 0.75rem !important;
}
.pt-0-75 {
  padding-top: 0.75rem !important;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body, h3 {
    background-color: transparent !important;
    font-size: 27px;
    font-family: 'Pyidaungsu' !important;
    color: #000000 !important;
  }
  p {
    line-height: 2.5rem !important;
  }
  img {
    width: 164px;
  }
  .table thead th, .table tfoot th {
    font-size: 27px;
  }
  .w-120px {
    width: 160px;
  }
  .table-bordered th, .table-bordered td {
    border: 1px solid #000 !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pr-2rem {
    padding-right: 5px !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .print-container {
    > [class*="col-"] {
      flex: 0 0 100%;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  [dir] .card {
    border: none !important;
  }
  [dir] .card-body {
    padding: 0;
  }
  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
